import React from "react";

export default () => (
    <div className="flex flex-space pt-l pb-l pl-l pr-l" style={{ bottom: '0px', backgroundColor: 'white'}}>
        {/* <div>
            <h1>Column 1</h1>
        </div>
        <div>
            <h1>Column 2</h1>
        </div>
        <div>
            <h1>Column 3</h1>
        </div> */}
    </div>
);